<template>
  <div class="pageContol enterpriseInternalTrainingOrder">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业内训</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">资讯管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
           
            <div title="标题名称" class="searchboxItem ci-full">
              <span class="itemLabel">标题名称:</span>
              <el-input
                v-model="searchData.informationName"
                size="small"
                placeholder="请输入标题名称"
                clearable
              />
            </div>
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker
            v-model="searchData.createTime"
            type="daterange"
            size="small"
            range-separator="至"
            value-format="yyyy-MM-dd"
            value="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
            </div>
            <div class="df" style="padding-right: 40px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="handleAddEditInformation('add', informationId = '')"
                >新增资讯</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="标题名称"
                align="center"
                prop="informationName"
                show-overflow-tooltip
              />
              <el-table-column
                label="上次更新时间"
                align="center"
                prop="lastReleaseTime"
              />
              <el-table-column
                label="资讯状态"
                align="center"
                prop="informationType"
              >
              <template slot-scope="scope">
                {{$setDictionary('SUBJECT_NEW_STATE', scope.row.informationType)}}
              </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
              >
                <template slot-scope="scope">
                 
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleAddEditInformation('edit', scope.row.informationId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleRecycleRelease(scope.row.informationId,scope.row.informationType)"
                    >{{scope.row.informationType == '10'?'发布':'回收'}}</el-button
                  >
                 
                 
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
 
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; //引入表格无数据提示
import PageNum from "@/components/PageNum.vue"; //引入分页-组件
import List from "@/mixins/List"; //引入列表 - 混入
import { resetKeepAlive } from "@/utils/common"; //缓存页面
import { mapGetters } from "vuex";
export default {
  name: "enterpriseInternalTrainingOrder",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //检索条件
      searchData: {
        informationName: "", //资讯名称
        createTime: "", //创建时间
      },
    };
  },
  created() {
  },
  mounted() {},
  computed: {},
  methods: {
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.informationName) {
        params.informationName = this.searchData.informationName;
      }
      if (this.searchData.createTime) {
        params.startCreateTime = this.searchData.createTime[0];
        params.endCreateTime = this.searchData.createTime[1];
      }
      this.doFetch({
        url: "/biz/information/pageList",
        params,
        pageNum,
      });
    },
    //新增编辑
    handleAddEditInformation(stu,informationId='') {
      this.$router.push({
        path: "/web/CustomPrograms/InformationManagementAddEdit",
        query: {
          informationId,
          stu,
        },
      });
    },
    //回收发布
    handleRecycleRelease(informationId,informationType) {
        this.$post('/biz/information/publish',{informationId,informationType:informationType == '10'?'20':'10'}).then(res => {
            if(res.status == '0') {
                this.$message.success(res.message);
                this.getData(-1)
            }
        })

    },
    //获取高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
    beforeRouteLeave: resetKeepAlive,
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh || val.query.refresh == "true") {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.enterpriseInternalTrainingOrder {
  .itemLabel {
    min-width: 6rem;
  }
}
.amountSummary {
  padding-left: 12px;
  span {
    color: red;
  }
}
.btnBox {
  display: flex;
  justify-content: center;
}
</style>
